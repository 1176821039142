<template>
  <span>
    {{ fileContainer == null ? '-' : fileContainer.displayName }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fileContainerId: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('fileContainers', ['getFileContainerById']),
    fileContainer () {
      return this.getFileContainerById(this.fileContainerId)
    }
  }
}
</script>
