<template>
  <div>
    <!-- Name -->
    <v-text-field
      :label="$t('files.filter.name-label')"
      v-model="filter.name"
      @change="propertyChanged"
      prepend-icon="mdi-file-search"
    />

    <!-- Extensions -->
    <v-autocomplete
      :label="$t('files.filter.extensions-label')"
      v-model="filter.extensions"
      :items="extensions"
      chips
      @change="propertyChanged"
      clearable
      deletable-chips
      multiple
      prepend-icon="mdi-file-question"
    />

    <!-- Containers -->
    <v-autocomplete
      :label="$t('files.filter.file-containers-label')"
      v-model="filter.fileContainerIds"
      :items="fileContainers"
      item-value="id"
      item-text="displayName"
      chips
      @change="propertyChanged"
      clearable
      deletable-chips
      multiple
      prepend-icon="mdi-folder-open"
    />

    <!-- Buttons -->
    <div class="d-flex flex-wrap justify-center mt-4 filter-buttons">
      <v-btn
        color="secondary"
        class="me-2"
        large
        @click="onResetClicked"
        :loading="loading"
      >
        {{ $t('files.filter.reset-button') }}
      </v-btn>
      <v-btn
        color="primary"
        class="ms-2"
        large
        @click="onSearchClicked"
        :loading="loading"
      >
        {{ $t('files.filter.search-button') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether the filter is busy.
     */
    loading: { type: Boolean, default: false }
  },
  emits: ['searchRequested', 'filterChanged'],
  data () {
    return {
      filter: {
        fileContainerIds: [],
        extensions: [],
        name: null
      }
    }
  },
  computed: {
    ...mapState('fileContainers', ['fileContainers']),
    ...mapState('files', ['extensions']),
    ...mapGetters('account', ['isAdmin'])
  },
  methods: {
    propertyChanged () {
      this.$emit('filterChanged', this.filter)
    },
    onResetClicked () {
      this.filter = {
        fileContainerIds: [],
        extensions: [],
        name: null
      }
      this.$emit('searchRequested', this.filter)
    },
    onSearchClicked () {
      this.$emit('searchRequested', this.filter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

// On very large devices...
.filter-buttons > * {
  width: 175px;

  // On large devices...
  @media #{map-get($display-breakpoints, 'lg-and-down')} {
    width: 115px;
  }
  // On medium devices...
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    width: 115px;
  }
  // On small devices...
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100px;
  }
}
</style>
