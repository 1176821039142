<template>
  <div>
    <!-- Filters for small devices -->
    <v-expansion-panels v-if="!$vuetify.breakpoint.mdAndUp" class="mb-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('common.filters') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FilesFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="isLoading"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <!-- Content -->
      <v-col cols="12" md="8" lg="9">
        <FilesTable
          :items="files"
          :total-size="totalSize"
          :page-size="pageSize"
          :page-number="pageNumber"
          :loading="isLoading"
          :features="features"
          :title="$t('files.table.title')"
          @pageChanged="pageChanged"
          @itemCreated="onItemCreated"
          @itemUpdated="onItemUpdated"
          @itemDeleted="onItemDeleted"
          @sortingChanged="onSortingChanged"
          @refreshRequested="onRefreshRequested"
        />
      </v-col>

      <!-- Filters for medium and large devices -->
      <v-col md="4" lg="3" v-if="$vuetify.breakpoint.mdAndUp">
        <Block :title="$t('common.filters')">
          <FilesFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="isLoading"
          />
        </Block>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import Block from '@/components/Block'
import FilesFilter from '@/components/filters/FilesFilter'
import FilesTable from '@/components/tables/FilesTable'

export default {
  components: { Block, FilesFilter, FilesTable },
  data () {
    return {
      files: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined,
      latestFilterData: {}
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['create', 'update', 'delete', 'download'] : ['create', 'update', 'download']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('files', ['fetchFiles', 'fetchAllExtensions']),
    ...mapActions('fileContainers', ['fetchAllFileContainers']),
    onFilterChanged (filter) {
      this.latestFilterData = filter
    },
    onItemUpdated (updatedFile) {
      // Try to find the updated file among the currently shown files.
      const index = this.files.findIndex((file) => file.id === updatedFile.id)
      if (index !== -1) {
        // Update the file to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.files, index, updatedFile)
      }
    },
    async onItemDeleted (deletedFile) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onItemCreated (newFile) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onSearchRequested (filter) {
      this.latestFilterData = filter
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, filter, sorting) {
      await this.fetchPage(pageNumber, pageSize, filter, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, filter, sorting) {
      this.isLoading = true
      try {
        const pagedResponse = await this.fetchFiles({ pageNumber, pageSize, filter, sorting })
        this.files = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await Promise.all([
      this.fetchAllFileContainers(),
      this.fetchAllExtensions()
    ])
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
