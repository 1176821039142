<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- File -->
              <v-col cols="12">
                <v-file-input
                  v-model="physicalFile"
                  :label="$t('files.dialog.file-input-label')"
                  accept=".json, .pdf, .doc, .docx, image/*, video/*, text/*"
                  show-size
                  clearable
                  :hint="editFileHint"
                  :persistent-hint="!isNew"
                  :disabled="isSaving"
                  :error-messages="fileValidationErrors"
                  :error-count="5"
                />
              </v-col>

              <!-- Container -->
              <v-col cols="12">
                <v-select
                  :items="fileContainers"
                  v-model="selectedFileContainer"
                  item-text="displayName"
                  :label="$t('files.dialog.file-container-label')"
                  :error-messages="validationErrors['fileContainerId']"
                  :error-count="5"
                  clearable
                  return-object
                  prepend-icon="mdi-folder-open"
                />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    file: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      physicalFile: null,
      isSaving: false,
      validationErrors: {},
      selectedFileContainer: null,
      lastSuccessfulLoad: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState('fileContainers', ['fileContainers']),
    ...mapGetters('fileContainers', ['getFileContainerById']),
    isNew () {
      return this.file == null
    },
    refetchImminent () {
      return !this.lastSuccessfulLoad || Date.now() - this.lastSuccessfulLoad > 60 * 1000
    },
    formTitle () {
      return this.$t(this.isNew ? 'files.dialog.create-title' : 'files.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    fileValidationErrors () {
      return this.combineValidationErrors(['file', 'fileName'])
    },
    editFileHint () {
      return this.isNew ? null : this.$t('files.dialog.file-input-edit-hint')
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('fileContainers', ['fetchAllFileContainers']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to create/update the item.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the item.
        const event = this.isNew ? 'itemCreated' : 'itemUpdated'
        this.$emit(event, response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.file') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.file') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const formData = new FormData()
      formData.append('file', this.physicalFile)
      formData.append('id', this.file.id)
      if (this.selectedFileContainer) {
        formData.append('fileContainerId', this.selectedFileContainer.id)
      }

      return await axios.put('files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async createNew () {
      const formData = new FormData()
      formData.append('file', this.physicalFile)
      if (this.selectedFileContainer) {
        formData.append('fileContainerId', this.selectedFileContainer.id)
      }

      return await axios.post('files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async fetchData () {
      try {
        this.isLoading = true
        await this.fetchAllFileContainers()
        // We need to update the selected file container, because the file containers might not've been fetched yet before this method.
        this.selectedFileContainer = this.file ? this.getFileContainerById(this.file.fileContainerId) : null
        this.lastSuccessfulLoad = Date.now()
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.close()
      } finally {
        this.isLoading = false
      }
    },
    combineValidationErrors (propertyNames) {
      return Object.entries(this.validationErrors).reduce((result, [key, errors]) => {
        return propertyNames.includes(key) ? result.concat(errors) : result
      }, [])
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Update the values if the dialog is being opened.
        if (newVal) {
          this.selectedFileContainer = this.file ? this.getFileContainerById(this.file.fileContainerId) : null
          this.validationErrors = {}

          if (this.refetchImminent) await this.fetchData()
        }
      }
    }
  }
}
</script>
